import request from "@/utils/request";

function versionAdd(data) {
  return request({
    url: "/show/version/add",
    method: "post",
    data,
  });
}

function versionDel(data) {
  return request({
    url: "/show/version/del.diti",
    method: "post",
    data,
  });
}

export { versionAdd, versionDel };
