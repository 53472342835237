<template>
  <div>
    <PagingQueryPage
      ref="PagingQueryPage"
      :queryFormState="queryFormState"
      size="middle"
      :bordered="true"
      rowKey="autoId"
      :columns="columns"
      :pagination="pagination"
      dataUrl="/show/version/filter.diti"
      :requestOptions="requestOptions"
    >
      <a-button type="primary" icon="plus" slot="action" @click="add"
        >新增</a-button
      >
    </PagingQueryPage>
    <a-modal
      :width="520"
      :visible="modalVisible"
      :title="(isAdd ? '新增' : '编辑') + modalTitle"
      ok-text="确认"
      cancel-text="取消"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="submit"
      @cancel="
        () => {
          modalVisible = false;
        }
      "
    >
      <a-form-model
        ref="modalForm"
        :model="modalFormState"
        :rules="modalRules"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
      >
        <a-form-model-item label="系统" prop="systemer">
          <a-select v-model="modalFormState.systemer">
            <a-select-option
              v-for="item in appSystemOptions"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="版本号" prop="versionNo">
          <a-input v-model="modalFormState.versionNo" />
        </a-form-model-item>
        <a-form-model-item label="是否强制更新" prop="forceUpdate">
          <a-select v-model="modalFormState.forceUpdate">
            <a-select-option
              v-for="item in appForceOptions"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="状态" prop="state">
          <a-select v-model="modalFormState.state">
            <a-select-option
              v-for="item in appStateOptions"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="包地址" prop="packageAddress">
          <a-input v-model="modalFormState.packageAddress" />
        </a-form-model-item>
        <a-form-model-item label="更新内容" prop="updateContent">
          <a-input v-model="modalFormState.updateContent" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import PagingQueryPage from "@/components/PagingQueryPage/diti.vue";
import { Modal, notification } from "ant-design-vue";
import { versionAdd, versionDel } from "@/apis/appVersion";
import {
  appSystemOptions,
  appForceOptions,
  appStateOptions,
} from "@/config/enum";
export default {
  components: { PagingQueryPage },
  data() {
    return {
      appSystemOptions,
      appForceOptions,
      appStateOptions,
      queryFormState: {},
      requestOptions: {
        companyId: "0",
      },
      columns: [
        {
          title: "序号",
          dataIndex: "",
          width: 80,
          align: "center",
          fixed: "left",
          scopedSlots: { customRender: "index" },
        },
        {
          title: "版本号",
          dataIndex: "versionNo",
          width: 200,
          align: "center",
        },
        {
          title: "系统",
          dataIndex: "systemer",
          width: 100,
          align: "center",
          customRender: (record) => {
            return appSystemOptions.find((r) => r.value == record)?.label
              ? appSystemOptions.find((r) => r.value == record)?.label
              : "";
          },
        },
        {
          title: "是否强制更新",
          dataIndex: "forceUpdate",
          width: 200,
          align: "center",
          customRender: (record) => {
            return appForceOptions.find((r) => r.value == record)?.label
              ? appForceOptions.find((r) => r.value == record)?.label
              : "";
          },
        },
        {
          title: "包地址",
          dataIndex: "packageAddress",
          width: 400,
          align: "center",
        },
        {
          title: "更新内容",
          dataIndex: "updateContent",
          align: "center",
        },
        {
          title: "状态",
          dataIndex: "state",
          width: 100,
          align: "center",
          customRender: (record) => {
            return appStateOptions.find((r) => r.value == record)?.label
              ? appStateOptions.find((r) => r.value == record)?.label
              : "";
          },
        },
        {
          title: "操作",
          dataIndex: "",
          width: 140,
          align: "center",
          fixed: "right",
          customRender: (record) => {
            return (
              <span class="action-group">
                <a-button
                  class="delBtn"
                  type="link"
                  onClick={() => {
                    this.del(record);
                  }}
                >
                  删除
                </a-button>
              </span>
            );
          },
        },
      ],
      pagination: {},
      modalVisible: false, // 弹窗状态
      isAdd: true, // 是否新增
      modalTitle: "APP版本", // 弹窗标题
      modalRules: {
        systemer: [{ required: true, message: "系统必选", trigger: "blur" }],
        versionNo: [{ required: true, message: "版本号必填", trigger: "blur" }],
        forceUpdate: [
          { required: true, message: "是否强制更新必选", trigger: "blur" },
        ],
        packageAddress: [
          { required: true, message: "包地址必填", trigger: "blur" },
        ],
        state: [{ required: true, message: "状态必选", trigger: "blur" }],
      }, // 弹窗校验规则
      modalFormState: {
        systemer: null,
        versionNo: null,
        forceUpdate: null,
        updateContent: null,
        packageAddress: null,
        state: null,
      }, // 弹窗值
    };
  },
  created() {},
  methods: {
    add() {
      this.modalVisible = true;
      this.isAdd = true;
      for (const key in this.modalFormState) {
        if (Object.hasOwnProperty.call(this.modalFormState, key)) {
          this.modalFormState[key] = null;
        }
      }
    },
    // edit(record) {
    //   this.modalVisible = true;
    //   this.isAdd = false;
    //   this.modalFormState.permissionId = record.permissionId;
    //   for (const key in this.modalFormState) {
    //     if (Object.hasOwnProperty.call(this.modalFormState, key)) {
    //       this.modalFormState[key] = record[key];
    //     }
    //   }
    // },
    submit() {
      this.$refs["modalForm"].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            versionAdd({
              ...this.requestOptions,
              ...this.modalFormState,
              ...this.queryFormState,
            }).then((res) => {
              const { code } = res.data;
              if (code === "200") {
                this.modalVisible = false;
                this.$refs["PagingQueryPage"].query();
                notification.success({
                  message: "新增成功",
                });
              }
            });
          } else {
            // permissionUpdate({ ...this.modalFormState }).then((res) => {
            //   const { code } = res.data;
            //   if (code === "200") {
            //     this.modalVisible = false;
            //     this.$refs["PagingQueryPage"].query();
            //     notification.success({
            //       message: "编辑成功",
            //     });
            //   }
            // });
          }
        }
      });
    },
    del(record) {
      Modal.confirm({
        title: `确定要删除该${this.modalTitle}吗？`,
        content: "注意：此操作无法撤销！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: () => {
          versionDel({
            autoId: record.autoId,
            versionNo: record.versionNo,
          }).then((res) => {
            const { code } = res.data;
            if (code === "200") {
              this.$refs["PagingQueryPage"].query();
              notification.success({
                message: "删除成功",
              });
            }
          });
        },
      });
    },
  },
};
</script>

<style></style>
